@import "./src/variables.scss";

.ErrorFallback {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: map-get($theme-colors, 'black');
  padding: 20px;

  &__title {
    color: #fff;
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    margin: 0;
    margin-bottom: 26px;
  }

  &__buttonContainer {
    width: 200px;
    display: flex;
    justify-content: center;
    gap: 12px;
    flex-direction: column;
    align-items: center;
  }
}