@import 'src/variables.scss';

.AccountSideNav {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: 100vh;
  background-color: map-get($fresh-theme-colors, 'grey-background');
  padding-top: 40px;
  transition: 0.2s ease-in-out all;

  @media (max-width: map-get($grid-breakpoints, lg)) {
    position: absolute;
    width: 240px;
    transform: translateX(-240px);
    left: 0;
    z-index: 1003;
    padding-top: 20px;

    &_open {
      transform: translateX(0);
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
  }

  &__toogleBtnLine {
    position: absolute;
    width: 18px;
    height: 2px;
    background: map-get($fresh-theme-colors, 'black');
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 5px;
  }

  &__toogleBtn {
    display: none;
    position: absolute;
    top: 21px;
    right: -42px;
    width: 18px;
    height: 18px;
    cursor: pointer;
    padding: 0;

    &::after,
    &::before {
      content: '';
      position: absolute;
      width: 18px;
      height: 2px;
      background: map-get($fresh-theme-colors, 'black');
      left: 0;
      border-radius: 5px;
    }

    &::before {
      top: 2px;
    }

    &::after {
      bottom: 2px;
    }

    &_close {
      border-top-width: 0;
      background-color: map-get($fresh-theme-colors, 'grey-background');
      width: 28px;
      height: 28px;
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
      border-radius: 6px;

      .AccountSideNav__toogleBtnLine {
        display: none;
      }

      &::after,
      &::before {
        top: 50%;
        left: 5px;
        transform: translate(-50%, -50%);
      }

      &::after {
        transform: rotate(45deg);
      }

      &::before {
        margin-top: 0;
        transform: rotate(-45deg);
      }
    }

    @media (max-width: map-get($grid-breakpoints, lg)) {
      display: block;
    }
  }

  &__link {
    display: flex;
    align-items: center;
    color: map-get($fresh-theme-colors, 'grey');
    text-decoration: none;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    padding: 16px;
    border-radius: 16px;
    margin-bottom: 12px;
    transition: 0.2s ease-in-out all;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      text-decoration: none;
      background: #ffffff;
      color: map-get($fresh-theme-colors, 'black');
    }

    &:focus {
      outline: none;
    }

    &_current {
      background: #ffffff;

      color: map-get($fresh-theme-colors, 'black');

      .AccountSideNav__icon {
        stroke: map-get($fresh-theme-colors, 'pink');

        &_filled {
          stroke: none;
          fill: map-get($fresh-theme-colors, 'pink');
        }
      }
    }
  }

  &__icon {
    width: 24px;
    height: auto;
    margin-right: 16px;

    &_filled {
      fill: map-get($fresh-theme-colors, 'grey');
    }

    &_small {
      width: 20px;
    }
  }

  &__linkContainer {
    padding: 0 24px;
  }

  &__brand {
    padding-left: 40px;
    margin-bottom: 40px;
  }

  &__loadContainer {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 0 24px;
  }
}